export default {
  common: {
    title: "Road trip Ford EV",
    bannerImg: "/img/homepage_cover_fr.jpg",
    languageSwitcherCta: "Changer de langue",
    languageSwitcherTitle: "Veuillez choisir une langue",
    menu: {
      ready: "On se<br />prépare",
      car: "Choix<br />de véhicule",
      roadtrip: "Votre<br />voyage",
      booking: "Réserver<br />essai routier",
    },
    continue: "Continuer",
    close: "Fermer",
  },
  index: {
    eventsCta: "RÉSERVEZ VOTRE ESSAI ROUTIER",
  },
  signup: {
    title: "Découvrez les véhicules {startWhite}électrifiés de Ford{endWhite}",
    heading: "Découvrez les véhicules {startBlue}électrifiés de Ford{endBlue}",
    text: "<p>Rêvez-vous d'un week-end rempli d'aventures au milieu de paysages à couper le souffle? Les gagnants du concours exploreront la beauté de leur province à bord d'un véhicule électrique Ford. Découvrez le mode de vie des véhicules électriques Ford lors de nos événements communautaires, où vous pourrez faire l'essai d'un véhicule électrique Ford, personnaliser votre road trip de rêve et gagner des prix incroyables.</p><p>Rejoignez-nous cet été pour le road trip ultime!</p>",
    name: "Nom",
    first_name: "Prénom",
    last_name: "Nom",
    email: "Courriel",
    phone: "Numéro de téléphone",
    driver_license: "Numéro de Permis de Conduire",
    driver_license_issuer: "Province d'émission",
    address: "Adresse domiciliaire",
    city: "Ville",
    province: "Province",
    zip: "Code Postal",
    is_driver: "Je suis un conducteur",
    vehicule: "Je vais faire un essai routier",
    tutor_full_name: "Nom et Prénom du Parent/Tuteur Légal",
    tutor_address: "Adresse domiciliaire",
    tutor_city: "Ville",
    tutor_province: "Province",
    tutor_zip: "Code Postal",
    terms:
      "J'ai lu et j'accepte les conditions d'utilisation ainsi que les règles de promotion. {terms}",
    termsLink: "Voir les détails ici.",
    optIn:
      "Cochez cette case pour recevoir des communications, y compris des courriels et des messages texte, de La Compagnie Ford du Canada Limitée et de La compagnie automobile Lincoln* (ensemble, « Ford »), ainsi que de votre concessionnaire/détaillant autorisé, à leur sujet ou concernant leurs produits et services, des événements, des nouvelles, des mises à jour, des offres, des promotions, des annonces personnalisées et plus.",
    legal:
      "*Vous pouvez retirer votre consentement en tout temps en suivant la procédure de désabonnement prévue dans ces messages ou en communiquant avec nous. Des frais de données peuvent s'appliquer. Ford Canada requiert votre consentement en son propre nom et au nom de votre concessionnaire/détaillant autorisé. Pour en savoir plus sur votre concessionnaire/détaillant autorisé et pour obtenir ses coordonnées, <a class='underline' href='https://www.fordclassic.ford.com/forms/DealerList.asp?Lang=F' target='_blank'>cliquer ici</a>. Pour consulter notre politique de confidentialité, qui traite notamment de votre droit d'accéder à vos renseignements personnels ou de les corriger, de votre droit de désinscription (suppression des listes de marketing), des pratiques de nos fournisseurs de services et du stockage de données aux États-Unis, <a class='underline' href='https://fr.ford.ca/help/privacy/' target='_blank'>cliquer ici</a>. Si vous avez des questions, vous pouvez communiquer avec un représentant du service à la clientèle en composant le 1 800 565-FORD (3673) ou nous écrire à l'adresse suivante : The Canadian Road, C.P. 2000, Oakville (Ontario), Canada L6K 0C8.<br />* La Compagnie Ford du Canada Limitée fait affaire sous la raison sociale de La compagnie automobile Lincoln.",
    next: "Suivant",
    eventNotFound: "Aucun évènement trouvé pour cette province.",
    error: "Une erreur est survenue. Veuillez réessayez plus tard.",
    "select-province": "Veuillez choisir la<br/> province de<br/> l'évenement",
  },
  validation: {
    title: "Validation",
    text: "Entrez le code qui vous a été envoyé à votre adresse courriel.",
    code: "Code",
    next: "Suivant",
    error: "Désolé, le code est erroné ou expiré.",
  },
  quiz: {
    already_completed: "Vous avez déjà complété cette section.",
    next: "Suivant",
  },
  gettingReady: {
    title:
      "Préparez-vous<br /> pour votre<br />{startBlue}Road trip EV{endBlue}",
    questions: {
      Question_1: {
        index: 0,
        question:
          "Si votre voyage en voiture était un genre cinématographique, quel serait-il?",
        next: "Question_2",
        answers: [
          {
            id: "action",
            label: "Film d'aventure rempli d'action",
            img: "/img/quiz/1/1A.jpg",
            infoImg: "/img/quiz/1/1Follow.jpg",
            infoTitle:
              "Peu importe votre destination, vous pouvez vous y rendre en toute confiance.",
            infoText:
              "Peu importe votre destination, vous pouvez vous y rendre en toute confiance. Les véhicules électriques offrent généralement une autonomie moyenne de <b>480 kilomètres</b> par charge, ce qui vous permet d'explorer diverses destinations sans craindre d'être à court d'énergie. De plus, grâce à l'application <b>FordPass</b>®, vous pouvez planifier vos déplacements à l'avance et accéder aux stations de recharge rapide le long de votre itinéraire pour une recharge pratique en cours de route!",
          },
          {
            id: "gourmet",
            label: "Film de découverte gastronomique",
            img: "/img/quiz/1/1B.jpg",
            infoImg: "/img/quiz/1/1Follow.jpg",
            infoTitle:
              "Peu importe votre destination, vous pouvez vous y rendre en toute confiance.",
            infoText:
              "Peu importe votre destination, vous pouvez vous y rendre en toute confiance. Les véhicules électriques offrent généralement une autonomie moyenne de <b>480 kilomètres</b> par charge, ce qui vous permet d'explorer diverses destinations sans craindre d'être à court d'énergie. De plus, grâce à l'application <b>FordPass</b>®, vous pouvez planifier vos déplacements à l'avance et accéder aux stations de recharge rapide le long de votre itinéraire pour une recharge pratique en cours de route!",
          },
          {
            id: "relaxing",
            label: "Film relaxant",
            img: "/img/quiz/1/1C.jpg",
            infoImg: "/img/quiz/1/1Follow.jpg",
            infoText:
              "Peu importe votre destination, vous pouvez vous y rendre en toute confiance. Les véhicules électriques offrent généralement une autonomie moyenne de <b>480 kilomètres</b> par charge, ce qui vous permet d'explorer diverses destinations sans craindre d'être à court d'énergie. De plus, grâce à l'application <b>FordPass</b>®, vous pouvez planifier vos déplacements à l'avance et accéder aux stations de recharge rapide le long de votre itinéraire pour une recharge pratique en cours de route!",
          },
        ],
      },
      Question_2: {
        index: 1,
        question: "À quoi vous identifiez-vous le plus?",
        next: "page_voiture",
        answers: [
          {
            id: "fuel",
            label: "Je connais ma voiture",
            img: "/img/quiz/2/2A.jpg",
            imgAlt:
              "Image de l'indicateur de carburant à « E » avec la légende « Je connais ma voiture »",
            infoTitle: "Le confiant",
            infoImg: "/img/quiz/2/2Afollow.jpg",
            infoText:
              "Pour une recharge de dernière minute de votre véhicule électrique, le réseau de recharge <b>BlueOval</b>™ est indispensable. En général, il faut environ 30 minutes pour recharger la batterie de votre véhicule jusqu'à 80 % de sa capacité à une borne de recharge rapide, et les coûts peuvent varier en fonction de l'emplacement et du fournisseur.",
          },
          {
            id: "topup",
            label: "Je dois faire le plein",
            img: "/img/quiz/2/2B.jpg",
            imgAlt:
              "Image de l'indicateur de carburant à ⅔ avec la légende « Je dois faire le plein »",
            infoTitle: "Le prévoyant",
            infoImg: "/img/quiz/2/2Bfollow.jpg",
            infoText:
              "Pour quelqu'un qui planifie toujours à l'avance, recharger son véhicule électrique à domicile est la solution la plus pratique et la plus économique. Avec une borne de recharge connectée, vous pouvez recharger entièrement votre VE pendant la nuit, ce qui vous donne une autonomie suffisante pour vos trajets quotidiens ou vos longs voyages.",
          },
        ],
      },
    },
  },
  car: {
    title: "Choisissez<br />{startBlue}le Ford BEV{endBlue}<br /> pour vous",
    questions: {
      Question_3: {
        index: 0,
        question: "Quel est votre personnalité au volant?",
        next: "Question_4",
        answers: [
          {
            id: "F150BEV",
            label: "Fort et performant",
            img: "/img/quiz/3/3A.jpg",
            infoTitle: "Fort et performant",
            infoImg: "/img/quiz/3/3Afollow.jpg",
            infoText:
              "Si vous appréciez la puissance, le Ford F‑150 Lightning® est l'évolution de l'emblématique F‑150®. Avec plus de puissance et de couple que jamais auparavant, il est conçu pour accomplir les tâches les plus difficiles et les trajets les plus trépidants avec aisance.",
          },
          {
            id: "MACHE",
            label: "Amateur de sensations fortes",
            img: "/img/quiz/3/3B.jpg",
            infoTitle: "Amateur de sensations fortes",
            infoImg: "/img/quiz/3/3Bfollow.jpg",
            infoText:
              "Si la vitesse est votre priorité, alors la Mustang Mach-E® est le choix parfait. Conçue pour des performances exaltantes, elle offre une expérience de conduite encore plus amusante et dynamique, alliant l'esprit de l'emblématique Mustang® à une technologie électrique de pointe.",
          },
        ],
      },
      Question_4a: {
        index: 1,
        question:
          "Vrai ou faux : Le F150 Lightning® peut passer de 0 à 100 km/h en moins de 6 secondes.",
        next: "Question_5a",
        answers: [
          {
            id: "true",
            label: "Vrai",
            img: "/img/quiz/4.1/4.1A.jpg",
            infoTitle: "Vrai : Plus de traction, plus de puissance",
            infoImg: "/img/quiz/4.1/4.1follow.jpg",
            infoText:
              "La F‑150 Lightning® peut accélérer de 0 à 100 km/h en seulement 4,5 secondes, mettant en évidence son niveau de performance électrisant grâce à deux moteurs électriques générant 580 chevaux et un couple instantané de 775 lb-pi. En comparaison, le moteur à essence V8 de 5 L du F‑150® génère 400 chevaux et 410 lb-pi de couple.",
          },
          {
            id: "false",
            label: "Faux",
            img: "/img/quiz/4.1/4.1B.jpg",
            infoTitle: "Vrai : Plus de traction, plus de puissance",
            infoImg: "/img/quiz/4.1/4.1follow.jpg",
            infoText:
              "La F‑150 Lightning® peut accélérer de 0 à 100 km/h en seulement 4,5 secondes, mettant en évidence son niveau de performance électrisant grâce à deux moteurs électriques générant 580 chevaux et un couple instantané de 775 lb-pi. En comparaison, le moteur à essence V8 de 5 L du F‑150® génère 400 chevaux et 410 lb-pi de couple.",
          },
        ],
      },
      Question_4b: {
        index: 1,
        question:
          "Vrai ou faux : La Mustang Mach-E® délivre un couple presque deux fois supérieur à son équivalent à essence.",
        next: "Question_5b",
        answers: [
          {
            id: "true",
            label: "Vrai",
            img: "/img/quiz/4.2/4.2A.jpg",
            infoTitle: "Vrai : Décollage à toute vitesse",
            infoImg: "/img/quiz/4.2/4.2follow.jpg",
            infoText:
              "La Mustang Mach-E® GT est équipée de deux moteurs électriques qui génèrent une puissance combinée de 487 chevaux et 634 lb-ft de couple, contre 391 lb-ft pour la Mustang® V8 de 5 L.",
          },
          {
            id: "false",
            label: "Faux",
            img: "/img/quiz/4.2/4.2B.jpg",
            infoTitle: "Vrai : Décollage à toute vitesse",
            infoImg: "/img/quiz/4.2/4.2follow.jpg",
            infoText:
              "La Mustang Mach-E® GT est équipée de deux moteurs électriques qui génèrent une puissance combinée de 487 chevaux et 634 lb-ft de couple, contre 391 lb-ft pour la Mustang® V8 de 5 L.",
          },
        ],
      },
      Question_5a: {
        index: 2,
        question: "Quelle est votre humeur au volant?",
        next: "Question_6a",
        answers: [
          {
            id: "red",
            label: "Rouge : Audacieux et énergique",
            img: "/img/quiz/5.1/5.1A.jpg",
          },
          {
            id: "silver",
            label: "Argent: Classieux et Intemporel",
            img: "/img/quiz/5.1/5.1B.jpg",
          },
          {
            id: "black",
            label: "Noir : Sophistiqué et puissant",
            img: "/img/quiz/5.1/5.1C.jpg",
          },
          {
            id: "blue",
            label: "Bleu : Calme et serein",
            img: "/img/quiz/5.1/5.1D.jpg",
          },
        ],
      },
      Question_5b: {
        index: 2,
        question: "Quelle est votre humeur au volant?",
        next: "Question_6b",
        answers: [
          {
            id: "red",
            label: "Rouge Rapide: Audacieux et énergetique",
            img: "/img/quiz/5.2/5.2A.jpg",
          },
          {
            id: "yellow",
            label: "Jaune accrocheur : Joyeux et optimiste",
            img: "/img/quiz/5.2/5.2B.jpg",
          },
          {
            id: "black",
            label: "Noir ombre : Sophistiqué et puissant",
            img: "/img/quiz/5.2/5.2C.jpg",
          },
          {
            id: "blue",
            label: "Bleu accrocheur : Calme et serein",
            img: "/img/quiz/5.2/5.2D.jpg",
          },
        ],
      },
      Question_6a: {
        index: 3,
        question: "Quel style de voiture vous convient le mieux?",
        next: "page_roadtrip",
        answers: [
          {
            id: "classic",
            label: "Classique : Intemporel et emblématique",
            img: "/img/quiz/6.1/6.1A.jpg",
            infoTitle: "Un style qui correspond à votre personnalité :",
            infoImg: "/img/quiz/6.1/6.1follow.jpg",
            infoText:
              "La F-150 Lightning® est disponible dans les styles suivants : <ul class='mt-2'><li><strong class='font-bold'>Pro:</strong> Travaillez fort, jouer fort.</li><li><strong class='font-bold'>XLT:</strong> Rendement à un niveau supérieur.</li><li><strong class='font-bold'>Flash™:</strong> Grande valeur, haute technologie.</li><li><strong class='font-bold'>Lariat®:</strong> Soyez un titan de la technologie.</li><li><strong class='font-bold'>Platinum:</strong> Vous pouvez tout avoir.</li></ul>",
          },
          {
            id: "rally",
            label: "Robuste : Aventureux et durable",
            img: "/img/quiz/6.1/6.1B.jpg",
            infoTitle: "Un style qui correspond à votre personnalité :",
            infoImg: "/img/quiz/6.1/6.1follow.jpg",
            infoText:
              "La F-150 Lightning® est disponible dans les styles suivants : <ul class='mt-2'><li><strong class='font-bold'>Pro:</strong> Travaillez fort, jouer fort.</li><li><strong class='font-bold'>XLT:</strong> Rendement à un niveau supérieur.</li><li><strong class='font-bold'>Flash™:</strong> Grande valeur, haute technologie.</li><li><strong class='font-bold'>Lariat®:</strong> Soyez un titan de la technologie.</li><li><strong class='font-bold'>Platinum:</strong> Vous pouvez tout avoir.</li></ul>",
          },
        ],
      },
      Question_6b: {
        index: 3,
        question: "Quel style de voiture vous convient le mieux?",
        next: "page_roadtrip",
        answers: [
          {
            id: "classic",
            label: "Classique : Intemporel et emblématique",
            img: "/img/quiz/6.2/6.2A.jpg",
            infoTitle: "Un style qui correspond à votre personnalité :",
            infoImg: "/img/quiz/6.2/6.2follow.jpg",
            infoText:
              "La Mustang Mach-E® est disponible dans les styles suivants : <ul class='mt-2'><li><strong class='font-bold'>Select:</strong> Passez à l'électrique.</li><li><strong class='font-bold'>Premium:</strong> Personnalisez votre véhicule.</li><li><strong class='font-bold'>GT:</strong> Rendement exaltant.</li><li><strong class='font-bold'>Mustang Mach-E® Rally:</strong> Le plaisir commence là où la route s'arrête.</li></ul>",
          },
          {
            id: "rally",
            label: "Rallye : Aventureux et polyvalent",
            img: "/img/quiz/6.2/6.2B.jpg",
            infoTitle: "Un style qui correspond à votre personnalité :",
            infoImg: "/img/quiz/6.2/6.2follow.jpg",
            infoText:
              "La Mustang Mach-E® est disponible dans les styles suivants : <ul class='mt-2'><li><strong class='font-bold'>Select:</strong> Passez à l'électrique.</li><li><strong class='font-bold'>Premium:</strong> Personnalisez votre véhicule.</li><li><strong class='font-bold'>GT:</strong> Rendement exaltant.</li><li><strong class='font-bold'>Mustang Mach-E® Rally:</strong> Le plaisir commence là où la route s'arrête.</li></ul>",
          },
        ],
      },
    },
  },
  roadtrip: {
    title:
      "Profitez de votre<br />{startBlue}expérience de road trip{endBlue} en véhicule électrique.",
    questions: {
      Question_7: {
        index: 0,
        question: "Aimez-vous vous faire conduire?",
        next: "Question_8",
        answers: [
          {
            id: "yes",
            label: "Oui!",
            img: "/img/quiz/7/7A.jpg",
            infoTitle: "",
            infoImg: "/img/quiz/7/7follow.jpg",
            infoText:
              "<span class='text-blue'>Le dispositif de conduite mains libres Blue Cruise*</span> de tous les VE Ford vous permet de vivre l'expérience de la conduite mains libres en toute sécurité en tant qu'adulte. Activez BlueCruise sur les autoroutes prises en charge et profitez de la conduite automatisée tout en gardant les mains détendues sur le volant, de manière à revivre la joie insouciante des aventures de votre enfance.<br /><small>*Caractéristique livrable. Les véhicules Ford équipés sont livrés avec une période d'essai gratuite de BlueCruise ou un forfait de services connectés BlueCruise offert à titre gracieux, après quoi l'achat d'un nouveau forfait est requis.</small>",
          },
          {
            id: "no",
            label: "Non!",
            img: "/img/quiz/7/7B.jpg",
            infoTitle: "",
            infoImg: "/img/quiz/7/7follow.jpg",
            infoText:
              "<span class='text-blue'>Le dispositif de conduite mains libres Blue Cruise*</span> de tous les VE Ford vous permet de vivre l'expérience de la conduite mains libres en toute sécurité en tant qu'adulte. Activez BlueCruise sur les autoroutes prises en charge et profitez de la conduite automatisée tout en gardant les mains détendues sur le volant, de manière à revivre la joie insouciante des aventures de votre enfance.<br /><small>*Caractéristique livrable. Les véhicules Ford équipés sont livrés avec une période d'essai gratuite de BlueCruise ou un forfait de services connectés BlueCruise offert à titre gracieux, après quoi l'achat d'un nouveau forfait est requis.</small>",
          },
        ],
      },
      Question_8: {
        index: 1,
        question: "Ça vous arrive de chanter sous la douche?",
        next: "Question_9",
        answers: [
          {
            id: "yes",
            label: "Oui! L'acoustique est irréelle.",
            img: "/img/quiz/8/8A.jpg",
            infoTitle: "",
            infoImg: "/img/quiz/8/8follow.jpg",
            infoText:
              "Le système <b>SYNC</b>™ et les services connectés de Ford vous aident à créer l'ambiance pour chaque trajet. Avec des fonctions telles que Ford <b>SYNC</b>™ pour diffuser votre musique, vos balados et vos livres audio, ou Alexa pour les commandes vocales, vous pouvez transformer chaque trajet en un concert personnalisé ou une séance de balado adaptée à vos préférences.",
          },
          {
            id: "no",
            label: "Non, je préfère me perdre dans mes pensées sous la douche.",
            img: "/img/quiz/8/8B.jpg",
            infoTitle: "",
            infoImg: "/img/quiz/8/8follow.jpg",
            infoText:
              "Le système <b>SYNC</b>™ et les services connectés de Ford vous aident à créer l'ambiance pour chaque trajet. Avec des fonctions telles que Ford <b>SYNC</b>™ pour diffuser votre musique, vos balados et vos livres audio, ou Alexa pour les commandes vocales, vous pouvez transformer chaque trajet en un concert personnalisé ou une séance de balado adaptée à vos préférences.",
          },
        ],
      },
      Question_9: {
        index: 2,
        question:
          "Avant de partir en voyage sur la route, à quel point inspectez-vous soigneusement votre voiture?",
        next: "Question_10",
        answers: [
          {
            id: "mechanic",
            label: "Inspection minutieuse digne d'un atelier de mécanique.",
            img: "/img/quiz/9/9A.jpg",
            infoTitle: "Inspection minutieuse digne d'un atelier de mécanique.",
            infoImg: "/img/quiz/9/9follow.jpg",
            infoText:
              "Que vous y accordez de l'importance ou non, les VE Ford sont équipés de dispositifs de sécurité avancés pour vous offrir la tranquillité d'esprit lors de chaque trajet. Des technologies d'aide à la conduite complètes à l'intégrité structurelle et aux matériaux robustes, Ford fait de  votre sécurité sur la route une priorité.",
          },
          {
            id: "tires",
            label: "Un coup de pied dans les pneus. Allons-y!",
            img: "/img/quiz/9/9B.jpg",
            infoTitle: "Un coup de pied dans les pneus. Allons-y!",
            infoImg: "/img/quiz/9/9follow.jpg",
            infoText:
              "Que vous y accordez de l'importance ou non, les VE Ford sont équipés de dispositifs de sécurité avancés pour vous offrir la tranquillité d'esprit lors de chaque trajet. Des technologies d'aide à la conduite complètes à l'intégrité structurelle et aux matériaux robustes, Ford fait de  votre sécurité sur la route une priorité.",
          },
        ],
      },
      Question_10: {
        index: 3,
        question: "Quel est votre objectif lors de vos aventures en plein air?",
        answers: [
          {
            id: "camping",
            label: "Camper sous les étoiles",
            img: "/img/quiz/10/10A.jpg",
            infoTitle: "Camper sous les étoiles",
            infoImg: "/img/quiz/10/10follow.jpg",
            infoText:
              "Que votre objectif soit de monter une tente ou de dévaler des sentiers, les VE Ford ont tout prévu grâce à la fonction <b>Pro-power Onboard</b> (embarqué). Qu'il s'agisse d'alimenter l'équipement de cuisine du camping ou de recharger les VTT électriques, profitez du grand air sans vous soucier de l'autonomie de la batterie.",
          },
          {
            id: "trails",
            label: "Dévaler des sentiers sur un VTT",
            img: "/img/quiz/10/10B.jpg",
            infoTitle: "Dévaler des sentiers sur un VTT",
            infoImg: "/img/quiz/10/10follow.jpg",
            infoText:
              "Que votre objectif soit de monter une tente ou de dévaler des sentiers, les VE Ford ont tout prévu grâce à la fonction <b>Pro-power Onboard</b> (embarqué). Qu'il s'agisse d'alimenter l'équipement de cuisine du camping ou de recharger les VTT électriques, profitez du grand air sans vous soucier de l'autonomie de la batterie.",
          },
        ],
      },
    },
  },
  legals: {
    title: "Conditions générales",
    content:
      "<p>Welcome to [Your Application Name]! Our application is designed to provide entertainment, and we're excited to have you on board. By using our application, you agree to these terms and conditions. If you disagree with any part of the terms, please do not use our application.</p><h3>Accounts</h3><p>To access some features of the application, you may need to create an account. Ensure that the information you provide is complete and current. You are responsible for safeguarding your account password and for all activities under your account.</p><h3>Use of Service</h3><p>[Your Application Name] is intended for entertainment purposes only. The information and content provided through the application are not meant to be accurate, reliable, or otherwise used for anything other than entertainment.</p><ul><li>Lorem</li><li>Ipsum</li><li>Dolores</li></ul><p>You agree not to use the application for any purpose that is illegal or prohibited by these terms.</p><h3>Content</h3><p>You understand that all information, such as text, graphics, or other material, is for entertainment and may not be accurate or reliable.</p><h3>Termination</h3><p>We may terminate or suspend access to our application immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the terms.</p><h3>Changes</h3><p>We reserve the right to modify or replace these terms at any time. By continuing to access or use our application after those revisions become effective, you agree to be bound by the revised terms.</p><h3>Contact Us</h3><p>If you have any questions about these terms, please contact us at [Your Contact Information]. By accessing or using [Your Application Name], you agree to be bound by these terms.</p>",
  },
  profile: {
    title: "Votre profil",
    explorer: {
      title: "L'implacable {startXL}aventurier{endXL}",
      text: "Vous aimez l'aventure. Qu'il s'agisse de conquérir des pistes tout-terrain ou de s'embarquer pour des voyages épiques, vous cherchez à vivre des expériences pleines d'adrénaline. Avec un esprit audacieux et un amour pour les grands espaces, vous êtes toujours prêt pour votre prochaine aventure.",
      bannerImg: "/img/quiz/profile/tough_adventurer.jpg",
      bannerAlt: "L'implacable aventurier",
      categoryA: "Film d'aventure rempli d'action",
      categoryAImg: "/img/profile_action.jpg",
      categoryB: "Fort et performant",
      categoryBImg: "/img/profile_f150.jpg",
      categoryBImgLabel: "F-150<br />Lightning",
    },
    glam: {
      title: "Le campeur {startXL}chic et libéré{endXL}",
      text: "Le luxe rencontre l'aventure à ciel ouvert. Vous appréciez les belles choses de la vie, vous vous régalez de délices gastronomiques au milieu de paysages naturels époustouflants. Avec un goût pour la sophistication et une passion pour l'exploration en plein air, vous redéfinissez le camping avec style.",
      bannerImg: "/img/quiz/profile/unbridled_glam_camper.jpg",
      bannerAlt: "Le campeur chic et libéré",
      categoryA: "Film d'aventure rempli d'action",
      categoryAImg: "/img/profile_action.jpg",
      categoryB: "Amateur de sensations fortes",
      categoryBImg: "/img/profile_machE.jpg",
      categoryBImgLabel: "Mustang<br />Mach-E",
    },
    epicurean: {
      title: "Le {startXL}fin-gourmet{endXL} intrépide",
      text: "Alliant robustesse et sens culinaire, vous savourez le frisson de l'aventure et le goût des délices gastronomiques. De la conquête de terrains accidentés aux expériences épicuriennes, vous incarnez le mélange parfait d'aventure et de raffinement.",
      bannerImg: "/img/quiz/profile/bold_gourmet.jpg",
      bannerAlt: "Le fin-gourmet intrépide",
      categoryA: "Film de découverte gastronomique",
      categoryAImg: "/img/profile_gourmet.jpg",
      categoryB: "Fort et performant",
      categoryBImg: "/img/profile_f150.jpg",
      categoryBImgLabel: "F-150<br />Lightning",
    },
    stylish: {
      title: "Le {startXL}gastronome{endXL} précurseur",
      text: "Avec un flair pour le style et une passion pour les aventures culinaires, vous appréciez les plaisirs de la vie avec sophistication. De l'exploration des rues animées de la ville à la dégustation de délices gastronomiques, vous appréciez les belles choses de la vie et voyagez toujours avec style.",
      bannerImg: "/img/quiz/profile/trailblazing_foodie.jpg",
      bannerAlt: "Le gastronome précurseur",
      categoryA: "Film de découverte gastronomique",
      categoryAImg: "/img/profile_gourmet.jpg",
      categoryB: "Amateur de sensations fortes",
      categoryBImg: "/img/profile_machE.jpg",
      categoryBImgLabel: "Mustang<br />Mach-E",
    },
    zen: {
      title: "Le {startXL}maître zen{endXL} endurant",
      text: "Vous aspirez à la sérénité dans chaque voyage. Ancré et calme, vous cherchez la tranquillité au milieu de la beauté de la nature, embrassant la paix du grand air. Avec un accent sur la pleine conscience et la paix intérieure, vous abordez chaque aventure avec un sentiment d'harmonie.",
      bannerImg: "/img/quiz/profile/rugged_zen_master.jpg",
      bannerAlt: "Le maître zen endurant",
      categoryA: "Film relaxant",
      categoryAImg: "/img/profile_relax.jpg",
      categoryB: "Fort et performant",
      categoryBImg: "/img/profile_f150.jpg",
      categoryBImgLabel: "F-150<br />Lightning",
    },
    relaxation: {
      title: "{startXL}L'expert original{endXL} de la relaxation",
      text: "Vous vous adonnez à l'art de la détente. Que vous vous détendez sur des plages paisibles ou que vous fassiez des voyages en voiture tranquilles à travers des paysages pittoresques, vous accordez la priorité à la paix et à la tranquillité dans chaque aventure. Avec une attitude détendue et un amour pour les loisirs, vous redéfinissez l'art de la détente.",
      bannerImg: "/img/quiz/profile/unconventional_relaxation_expert.jpg",
      bannerAlt: "L'expert original de la relaxation",
      categoryA: "Film relaxant",
      categoryAImg: "/img/profile_relax.jpg",
      categoryB: "Amateur de sensations fortes",
      categoryBImg: "/img/profile_machE.jpg",
      categoryBImgLabel: "Mustang<br />Mach-E",
    },
    book: "Réserver un essai routier",
  },
  survey: {
    title:
      "Remplissez votre sondage post-essai routier et recevez un cadeau de remerciement surprise.",
    submit: "Envoyer",
    wait: "Veuillez patienter...",
    error: "Une erreur est survenue. Veuillez réessayer.",
    back: "Retour au sondage",
    yes: "Oui",
    no: "Non",
    reset: "Réinitialiser",
    resetTimer: "Réinitialiser ({seconds})",
    success: "Merci!",
  },
  schedule: {
    title: "Réservez votre essai routier",
    next: "Suivant",
    skip: "Passer",
    modal: {
      heading: "Avez-vous réservé votre essai routier ?",
      content: "Vous ne pourrez pas réserver une fois que aurez commencé",
      yes: "Oui",
      back: "Revenir",
    },
  },
  book: {
    heading:
      "Réservez un essai routier{b} et découvrez les véhicules{b} électrifiés Ford",
    select: "Sélectionnez votre festival ci-dessous",
    back: "Retour",
  },
  success: {
    title: "Merci d'avoir réservé votre essai routier !",
    home: "Accueil",
  },
  waiver: {
    title: "Contrat de renonciation",
    text_1: `Allant être amené(e) à conduire un ou plusieurs véhicules dans le cadre de l'événement <b>La Grande Aventure Électrique</b> (l'"Événement") et de ma participation par ailleurs à l'Événement, moi, {name}, soussigné, au nom de moi-même et de quiconque obtient des droits de moi ou par moi, reconnais, représente et accepte ce qui suit :`,
    tutor_title:
      "*Complétez ce qui suit si la personne ci-dessus est mineure dans sa province ou son territoire de résidence :",
    tutor_description:
      "Je, soussigné(e), garantis et représente que je suis le parent ou le tuteur légal de {name} , dont la signature apparaît ci-dessus. J'ai lu l'accord de décharge ci-dessus et en connais parfaitement le contenu et je donne par la présente ma permission et mon consentement à tout ce qui précède.",
    admin_warning:
      "Les Administrateurs n'utiliseront aucune information personnelle fournie dans cette renonciation autrement que dans les buts décrits ci-dessus et ne transféreront ni ne rendront disponible l'information personnelle à toute autre personne, organisation ou tierce partie, sauf si nécessaire selon la loi.",
    submit: "J'ai lu et approuvé ce contrat de renonciation.",
    success: "Merci!",
    error: "Quelque chose s'est mal passé. Veuillez réessayer plus tard...",
    reset: "Réinitialiser ({seconds})",
    optIn: "Cochez ici pour recevoir des communications, y compris des e-mails, de Ford Motor Company of Canada, Limitée (Ford du Canada) et de la Lincoln Motor Company (Lincoln)* concernant nos produits, services, événements, actualités, mises à jour, offres, promotions, publicités personnalisées et plus encore. Vous pouvez retirer votre consentement à tout moment. La Politique de confidentialité de Ford du Canada, y compris votre droit d'accès ou de correction de vos informations personnelles, de refus (retrait des listes de marketing), nos pratiques de prestataires de services et le stockage des données aux États-Unis sont disponibles auprès du personnel de l'événement sur place. Si vous avez des questions, vous pouvez également contacter un représentant du service client au 1-800-565-FORD (3673) ou nous écrire à The Canadian Road P.O. Box 2000, Oakville, Ontario, Canada L6K 0C8.",
    termsText: "<p>*Ford Motor Company of Canada, Limited exerce ses activités sous le nom de The Lincoln Motor Company.</p><br/><p>LE PERSONNEL DE L'ÉVÉNEMENT SE RÉSERVE LE DROIT DE REFUSER TOUT PARTICIPANT DÉMONTRANT UN COMPORTEMENT INDISCIPLINÉ OU INAPPROPRIÉ QUI POURRAIT ENTRAÎNER DES BLESSURES POUR EUX-MÊMES OU POUR D'AUTRES, OU DES DOMMAGES AUX BIENS. LA POSSESSION OU L'UTILISATION DE TOUTES BOISSONS ALCOOLISÉES OU SUBSTANCES ILICITES ENTRAÎNERA L'EXCLUSION AUTOMATIQUE DU PARTICIPANT DE L'ÉVÉNEMENT. CES DÉTERMINATIONS DE REFUS/D'EXCLUSION SERONT À LA SEULE DISCRÉTION DU PERSONNEL DE CONDUITE ET DE L'ÉVÉNEMENT FORD, DONT LES DÉCISIONS SERONT FINALES EN TOUS POINTS.</p><br/><p>Les termes et dispositions du présent instrument lieront les héritiers, exécuteurs testamentaires ou administrateurs du soussigné et quiconque obtiendra des droits du ou par le biais du soussigné. Je reconnais avoir lu et compris pleinement tout ce qui précède, je suis responsable de mes actions et je comprends avoir renoncé à des droits substantiels en signant ce document et l'avoir signé librement et sans aucune incitation ou assurance de quelque nature que ce soit, et je souhaite que cela soit une LIBÉRATION COMPLÈTE ET INCONDITIONNELLE DE TOUTE RESPONSABILITÉ dans la plus grande mesure permise par la loi. Je reconnais avoir eu la possibilité de ne pas participer aux Activités si je ne souhaite pas exécuter ce document.<p>",
    text: `
    <ul type="1">
      <li>
        <b>Prise en charge de tous les risques</b><br/>
        Je comprends parfaitement que l'utilisation, la conduite, étant passager, l'exploitation, l'inspection ou le test de ladite voiture ou voitures ou toute autre participation à l'Événement (collectivement, les "Activités") peuvent être DANGEREUX et comporter des risques connus et imprévus pouvant entraîner des BLESSURES CORPORELLES, UNE MALADIE, LA MORT, DES DOMMAGES OU UNE PERTE pour moi-même, mes biens et pour d'autres tiers et leurs biens, qui peuvent être causés par mes propres actions, ou inactions, celles des autres participants aux Activités, du (des) véhicule(s), des routes, des surfaces, des installations, des conditions météorologiques, des autres véhicules, des personnes ou des obstacles, et d'autres conditions dans lesquelles les Activités se déroulent, ou par la négligence ou le comportement répréhensible de l'un des "Bénéficiaires" nommés ci-dessous (y compris, sans limitation, la négligence ou le comportement répréhensible du conducteur ou des passagers du véhicule dans lequel je suis passager); et j'accepte pleinement et ASSUME TOUS CES RISQUES ainsi que toute responsabilité pour les pertes, les coûts, les blessures, la mort et/ou les dommages que je, ou des tiers, subissent en raison de ma participation aux Activités, même si de telles blessures, décès ou dommages matériels surviennent d'une manière qui n'est pas prévisible au moment où je signe cette décharge. Je déclare comprendre la nature des Activités, y compris, sans limitation, les dangers inhérents à la conduite ou à être passager dans un véhicule sur les routes publiques.
      </li>

      <li>
        <b>Libération et Décharge </b><br/>
        Par la présente, je libère à jamais et de manière irrévocable, INDEMNISE, DÉFENDS ET EXONÈRE Ford Motor Company of Canada, Limitée ("Ford"), Cinco, et tous leurs parents respectifs, filiales, sociétés affiliées, dirigeants, directeurs, gestionnaires, représentants, agents, employés et successeurs et ayants droit (collectivement, les "Bénéficiaires"), de et contre toute action, cause d'action, réclamation, dommage, perte, demande ou responsabilité de quelque nature que ce soit, y compris, mais sans s'y limiter, les blessures corporelles, le décès ou les dommages matériels (collectivement, les "Pertes") découlant de, ou de quelque manière liées à, la participation du soussigné aux Activités, y compris, mais sans s'y limiter, les Pertes causées ou prétendument causées, en tout ou en partie, par LA NÉGLIGENCE DE TOUT BÉNÉFICIAIRE ou autrement. Les Pertes incluent également tous les coûts, pertes ou dépenses y afférents découlant de, ou par raison de, tout dommage matériel survenant à la suite de tout accident ou événement pendant, ou en relation avec, lesdites Activités, qu'ils soient négligents ou non. Je comprends que je pourrais par la suite découvrir des réclamations, des faits, des questions et/ou des Pertes en plus de celles que je connais ou crois actuellement être vraies concernant les Activités et les questions discutées ici. Néanmoins, j'ai l'intention de renoncer pleinement et complètement et de libérer les Bénéficiaires de toutes ces réclamations, questions et Pertes inconnues. JE CONSENS À NE PAS INTENTER DE POURSUITES contre aucun Bénéficiaire ni à engager aucune action en justice contre aucun Bénéficiaire concernant toute question libérée dans les présentes.
      </li>

      <li>
        <b>Certification </b><br/>
        Je certifie que :<br/>
        <ol type="I">
          <li>
            J'ai 18 ans ou plus, je possède un permis de conduire valide et suis autorisé à conduire dans le lieu où se déroule l'Événement ; 
          </li>
          <li>
            Je suis titulaire d'une assurance automobile valide pendant toute la période de test, qui couvre ma conduite du ou des véhicules pendant l'Événement ; 
          </li>
          <li>
            Je ne suis pas au courant d'une condition médicale ou d'une autre circonstance pouvant altérer ma capacité à participer aux Activités ou pouvant représenter un risque excessif pour moi-même, les autres participants aux Activités, les Bénéficiaires et/ou toute autre personne ;
          </li>
          <li>
            Avant de participer à l'Événement, je n'ai pas consommé, et pendant l'Événement, je ne consommerai pas, de boissons alcoolisées ou de substances illicites.
          </li>
        </ol>
      </li>

      <li>
        <b>Conformité aux lois</b><br/>
        Je m'engage à respecter toutes les lois, codes et réglementations applicables en matière de véhicules automobiles, et à faire preuve du même niveau de prudence et de diligence que j'utiliserais avec tout véhicule en ma possession (mais pas moins qu'un soin raisonnable). De plus, je m'engage à ne pas autoriser qui que ce soit à conduire le(s) véhicule(s).
      </li>

      <li>
        <b>Utilisation des ceintures de sécurité et des dispositifs de retenue</b><br/>
        Je m'engage à garantir l'utilisation correcte des ceintures de sécurité et des dispositifs de retenue pour enfants pour tous les occupants du ou des véhicules. Je m'engage à suivre toutes les instructions données par les Bénéficiaires.
      </li>

      <li>
        <b>Autorisation de traitement médical </b><br/>
        Par la présente, j'autorise tout traitement médical jugé nécessaire en cas de blessure ou de maladie survenue pendant ma participation aux Activités. Je dispose soit d'une assurance appropriée, soit, en son absence, je m'engage à payer tous les frais de secours et/ou de services médicaux pouvant être engagés en mon nom. Nonobstant ce qui précède, le présent document n'impose aucune obligation (au-delà de toute obligation légale applicable) à aucun des Bénéficiaires d'agir en cas d'urgence médicale me concernant et par la présente, JE RENONCE ET LIBÈRE les Bénéficiaires de toute perte de quelque nature que ce soit, que celle-ci soit causée par la négligence des Bénéficiaires ou pour toute autre raison, découlant de ou en relation avec toute maladie, blessure corporelle ou décès me concernant de quelque manière que ce soit liée aux actions ou à l'omission d'agir des Bénéficiaires dans le cadre de cette autorisation de traitement médical en mon nom.
      </li>

      <li>
        <b>Autorisation d'utilisation d'image</b><br/>

        Par la présente :
        <ol>
          <li>J'accorde aux Bénéficiaires le droit d'utiliser mon nom, ma voix, mon apparence physique (sous quelque format que ce soit, y compris photographie et vidéo, que celles-ci soient prises/enregistrées par les Bénéficiaires ou par moi-même et fournies aux Bénéficiaires) et mes opinions/déclarations personnelles exprimées concernant Ford et ses véhicules, fonctionnalités et services (le "Matériel");</li>
          <li>Je reconnais et accepte que je n'acquerrai ni ne conserverai aucun droit d'auteur ou autre droit de propriété intellectuelle ou commercial, y compris les droits moraux sur mon Matériel;</li>
          <li>Je reconnais et accepte que le Matériel, ainsi que ses traductions, peuvent être utilisés par les Bénéficiaires aussi souvent et aussi longtemps que les Bénéficiaires le jugent approprié à leur seule discrétion;</li>
          <li>Je reconnais et accepte que je ne recevrai aucune rémunération, redevance ou autre compensation pour l'utilisation du Matériel, et de ses traductions, par les Bénéficiaires;</li>
          <li>Je reconnais et accepte que les Bénéficiaires peuvent utiliser le Matériel (et ses traductions), en tout ou en partie, sans préavis ni consentement supplémentaire, dans tous les médias, y compris, sans limitation, en diffusion (par exemple, télévision, radio et web), et sous toutes les formes, telles que l'utilisation de mon image (photographie ou vidéo), de ma voix et/ou de mes représentations écrites;</li>
          <li>Je renonce à tout droit d'inspection et/ou d'approbation du produit fini ou de la copie publicitaire qui peut être utilisée à cet effet;</li>
          <li>Je certifie que le contenu du Matériel m'appartient, est original, véridique, basé sur ma connaissance personnelle et mon expérience, ne fait référence à ou ne dépeint aucune personne ou entité (autre que moi-même et les Bénéficiaires) sans leur permission expresse, ne viole pas les droits d'auteur, les marques déposées, les droits à la vie privée, les droits à l'image ou autres droits de propriété intellectuelle ou autres droits de toute personne ou entité (autre que moi), a été créé de manière légale et sûre, et m'appartient;</li>
          <li>Je reconnais et accepte que je suis conscient que seule une partie du Matériel peut être utilisée par les Bénéficiaires, et à cette fin, je confirme que chaque déclaration et représentation dans le Matériel est vraie, même si reproduite individuellement et sans référence à la totalité du Matériel</li>
          <li>Je comprends et accepte que je n'aurai pas la possibilité de choisir si le Matériel, et ses traductions, seront utilisés en tout ou en partie, et si seulement utilisés en partie, je reconnais et accepte que je n'aurai pas la possibilité de décider quelle partie sera utilisée; </li>
          <li>Je libère, décharge à jamais et m'engage à protéger les Bénéficiaires de toute action, cause d'action ou réclamation survenue jusqu'à ce jour ou à l'avenir et à laquelle je pourrais avoir droit pour quelque cause, sujet ou chose que ce soit existant ou survenant en relation avec l'utilisation du matériel. </li>
        </ol>
      </li>

      <li>
        <b>General</b><br/>
        Par la présente, je renonce à jamais et de manière irrévocable à tous droits, le cas échéant, de révocation, limitation ou désaveu du présent document. Si une disposition du présent document est inapplicable, elle sera modifiée dans la mesure raisonnablement nécessaire pour rendre la disposition légale, valide et contraignante, et le reste du présent document restera exécutoire dans toute la mesure permise par la loi. Aucune déclaration concernant l'effet du présent document, non contenue dans le présent document, ne modifiera les termes du présent document. Je fais ces représentations en comprenant parfaitement que les Bénéficiaires se fient à ces représentations. Dans toute procédure ou tentative de faire respecter, interpréter ou déterminer la validité du présent document, la partie perdante paiera les frais raisonnables de la partie gagnante, y compris, sans limitation, les honoraires et frais juridiques raisonnables. Cette libération sera régie et interprétée conformément aux lois de l'Ontario, Canada, sans égard aux principes de conflit de lois. La juridiction et le lieu exclusifs pour tout litige concernant cette libération seront en Ontario, Canada, et je soumets par la présente à la juridiction personnelle exclusive de ces tribunaux pour tout litige et renonce à jamais à tous droits de contester cette juridiction 
      </li>
    </ul>`,
  },
  provinces: {
    britishColumbia: "Colombie Britanique",
    ontario: "Ontario",
    quebec: "Québec"
  }
};
