export default {
  common: {
    title: "EN Ford EV Roadtrip",
    bannerImg: "/img/homepage_cover_en.jpg",
    languageSwitcherCta: "Change language",
    languageSwitcherTitle: "Please choose a language",
    menu: {
      ready: "Getting<br />ready",
      car: "Choosing<br />your car",
      roadtrip: "Your<br />roadtrip",
      booking: "Book your<br />test drive",
    },
    continue: "Continue",
    close: "Close",
  },
  index: {
    eventsCta: "BOOK YOUR TEST DRIVE",
  },
  signup: {
    title: "Experience Ford<br />{startWhite}Electrified Vehicles{endWhite}",
    heading: "Experience Ford<br />{startBlue}Electrified Vehicles{endBlue}",
    text: "<p>Dreaming of an adventure-filled weekend amidst breathtaking landscapes? Contest winners will explore their province's beauty in a Ford EV. Explore the Ford EV lifestyle at our community events, where you can test drive a Ford EV, personalize your dream road trip, and win amazing prizes.</p><p><b>Join us this summer for the ultimate road trip!</b></p>",
    name: "Name",
    first_name: "First name",
    last_name: "Last name",
    email: "Email",
    phone: "Phone",
    driver_license: "Driver's License Number",
    driver_license_issuer: "Province Issued",
    address: "Home Address",
    city: "City",
    province: "Province",
    zip: "Postal Code",
    is_driver: "I am a driver",
    vehicule: "I will be test driving...",
    tutor_full_name: "Last Name, First Name of Parent or Legal Tutor",
    tutor_address: "Home Address",
    tutor_city: "City",
    tutor_province: "Province",
    tutor_zip: "Postal Code",
    terms: "I have read and accept the terms and conditions and the rules and regulations of the contest. {terms}",
    termsLink: "See details here.",
    optIn:
      "Check here to receive communications, including emails and text messages, from Ford Motor Company of Canada, Limited and The Lincoln Motor Company* (together, Ford), and your authorized dealership about them and their products, services, events, news, updates, offers, promotions, customized ads and more.",
    legal:
      "You may withdraw consent at any time by using the unsubscribe mechanism provided in such messages or by contacting us. Data charges may apply. Ford is seeking consent on its own behalf and on behalf of your authorized dealership. For more information about and how to contact your authorized dealership, please <a class='underline' href='https://www.fordclassic.ford.com/forms/dealerlist.asp?Lang=E' target='_blank'>click here</a>. To review our Privacy Policy, including your right to access or correct your personal information, opt-out (deletion from marketing lists), our service provider practices and storage of data in the U.S. <a class='underline' href='https://www.ford.ca/help/privacy/' target='_blank'>click here</a>. <br/><br/>If you have any questions you may contact a Customer Service Representative at 1-800-565-FORD (3673) or write us at The Canadian Road P.O. Box 2000, Oakville, Ontario, Canada L6K 0C8.<br/><br/> * Ford Motor Company of Canada, Limited carries on business as The Lincoln Motor Company.</p>",
    next: "ENTER CONTEST",
    eventNotFound: "No event found for this province",
    error: "There was an error. Please try later.",
    "select-province": "Please select the<br/> event's province",
  },
  validation: {
    title: "Validation",
    text: "Enter the code that was sent to your email address.",
    code: "Code",
    next: "Next",
    error: "Sorry the code is wrong or expired.",
  },
  quiz: {
    already_completed: "You have already answered this section.",
    next: "Next",
  },
  gettingReady: {
    title: "Getting ready<br /> for your<br />{startBlue}EV Roadtrip{endBlue}",
    questions: {
      Question_1: {
        index: 0,
        question: "If your road trip were a movie, what would it be?",
        next: "Question_2",
        answers: [
          {
            id: "action",
            label: "An Action-packed Adventure",
            img: "/img/quiz/1/1A.jpg",
            infoImg: "/img/quiz/1/1Follow.jpg",
            infoTitle: "Wherever your chosen adventure may be, you can get there confidently.",
            infoText:
              "Explore long distances and diverse destinations with confidence. Ford electric vehicles typically offer an average range of <b>480</b> kilometers per charge, making most trips a breeze. Plus, with the <b>FordPass</b>® app, you never have to worry about the power running out! Plan your route in advance and access fast-charging stations whenever you need a quick, convenient recharge on the go!",
          },
          {
            id: "gourmet",
            label: "An Extraordinary Culinary Experience",
            img: "/img/quiz/1/1B.jpg",
            infoImg: "/img/quiz/1/1Follow.jpg",
            infoTitle: "Wherever your chosen adventure may be, you can get there confidently.",
            infoText:
              "Explore long distances and diverse destinations with confidence. Ford electric vehicles typically offer an average range of <b>480</b> kilometers per charge, making most trips a breeze. Plus, with the <b>FordPass</b>® app, you never have to worry about the power running out! Plan your route in advance and access fast-charging stations whenever you need a quick, convenient recharge on the go!",
          },
          {
            id: "relaxing",
            label: "A Relaxing Retreat",
            img: "/img/quiz/1/1C.jpg",
            infoImg: "/img/quiz/1/1Follow.jpg",
            infoTitle: "Wherever your chosen adventure may be, you can get there confidently.",
            infoText:
              "Explore long distances and diverse destinations with confidence. Ford electric vehicles typically offer an average range of <b>480</b> kilometers per charge, making most trips a breeze. Plus, with the <b>FordPass</b>® app, you never have to worry about the power running out! Plan your route in advance and access fast-charging stations whenever you need a quick, convenient recharge on the go!",
          },
        ],
      },
      Question_2: {
        index: 1,
        question: "To which do you relate the most?",
        next: "page_car",
        answers: [
          {
            id: "fuel",
            label: "I know my car",
            img: "/img/quiz/2/2A.jpg",
            infoTitle: "The Fuel Brink Enthusiast",
            infoImg: "/img/quiz/2/2Afollow.jpg",
            infoText:
              "For last minute charging of your electric vehicle, the <b>BlueOval™ Charge Network</b> is a must. Typically, it takes around 30 minutes to charge to 80% capacity at a fast-charging station, and costs can vary depending on location and provider.",
          },
          {
            id: "topup",
            label: "Need to fill up",
            img: "/img/quiz/2/2B.jpg",
            infoTitle: "The Top-up Fanatic",
            infoImg: "/img/quiz/2/2Bfollow.jpg",
            infoText:
              "For someone that always plans ahead, charging your electric vehicle at home is the most convenient and cost-effective option. With a <b>Ford Connected Charge Station</b>, you can fully charge your EV overnight, providing ample range for your daily commute or longer road trips.",
          },
        ],
      },
    },
  },
  car: {
    title: "Choose<br />{startBlue}the Ford BEV{endBlue}<br /> for you",
    questions: {
      Question_3: {
        index: 0,
        question: "What's your personality behind the wheel?",
        next: "Question_4",
        answers: [
          {
            id: "F150BEV",
            label: "Rugged and Capable",
            img: "/img/quiz/3/3A.jpg",
            infoTitle: "Rugged and Capable",
            infoImg: "/img/quiz/3/3Afollow.jpg",
            infoText:
              "If you are all about toughness and muscle, the <b>Ford F‑150 Lightning</b>® is what you need. With more power and torque than ever before, it's a natural evolution of the iconic <b>F‑150</b>®, and built to handle tough jobs and challenging drives with ease.",
          },
          {
            id: "MACHE",
            label: "Unbridled Rebel",
            img: "/img/quiz/3/3B.jpg",
            infoTitle: "Unbridled Rebel",
            infoImg: "/img/quiz/3/3Bfollow.jpg",
            infoText:
              "If you are the unconventional type, then the <b>Mustang Mach‑E</b>® is the perfect choice. Designed for exhilarating performance, it delivers a fun and dynamic driving experience, blending the <b>60 year heritage of the Mustang</b>® with cutting-edge electric technology.",
          },
        ],
      },
      Question_4a: {
        index: 1,
        question: "True or False: The F-150 Lightning® can do 0-60 mph under 6 secs",
        next: "Question_5a",
        answers: [
          {
            id: "true",
            label: "True",
            img: "/img/quiz/4.1/4.1A.jpg",
            infoTitle: "True: More Torque, more Power",
            infoImg: "/img/quiz/4.1/4.1follow.jpg",
            infoText:
              "The <b>F‑150 Lightning</b>® can accelerate from 0 to 60 mph in just 4.5 seconds, showcasing its electrifying level of performance with dual electric motors generating 580 horsepower and 775 lb-ft of instant torque. In comparison, the <b>F‑150</b>® 5L V8 gas engine generates 400HP and 410 lb-ft of torque.",
          },
          {
            id: "false",
            label: "False",
            img: "/img/quiz/4.1/4.1B.jpg",
            infoTitle: "True: More Torque, more Power",
            infoImg: "/img/quiz/4.1/4.1follow.jpg",
            infoText:
              "The <b>F‑150 Lightning</b>® can accelerate from 0 to 60 mph in just 4.5 seconds, showcasing its electrifying level of performance with dual electric motors generating 580 horsepower and 775 lb-ft of instant torque. In comparison, the <b>F‑150</b>® 5L V8 gas engine generates 400HP and 410 lb-ft of torque.",
          },
        ],
      },
      Question_4b: {
        index: 1,
        question: "True or False: The Mustang Mach-E® delivers almost twice the torque of its gas equivalent.",
        next: "Question_5b",
        answers: [
          {
            id: "true",
            label: "True",
            img: "/img/quiz/4.2/4.2A.jpg",
            infoTitle: "True: Take off like a bullet",
            infoImg: "/img/quiz/4.2/4.2follow.jpg",
            infoText:
              "The Mustang Mach‑E GT features two electric motors that generate a combined output of 487 horsepower and 634 lb-ft of torque as opposed to the 5L V8 Mustang's 391 lb-ft.",
          },
          {
            id: "false",
            label: "False",
            img: "/img/quiz/4.2/4.2B.jpg",
            infoTitle: "True: Take off like a bullet",
            infoImg: "/img/quiz/4.2/4.2follow.jpg",
            infoText:
              "The <b>Mustang Mach‑E</b>® GT features two electric motors that generate a combined output of 487 horsepower and 634 lb-ft of torque as opposed to the 5L V8 <b>Mustang</b>®'s 391 lb-ft.",
          },
        ],
      },
      Question_5a: {
        index: 2,
        question: "What is your mood behind the wheel?",
        next: "Question_6a",
        answers: [
          {
            id: "red",
            label: "Red: Bold and Energetic",
            img: "/img/quiz/5.1/5.1A.jpg",
          },
          {
            id: "silver",
            label: "Silver: Classic and Timeless",
            img: "/img/quiz/5.1/5.1B.jpg",
          },
          {
            id: "black",
            label: "Black: Sophisticated and Powerful",
            img: "/img/quiz/5.1/5.1C.jpg",
          },
          {
            id: "blue",
            label: "Blue: Calm and Serene",
            img: "/img/quiz/5.1/5.1D.jpg",
          },
        ],
      },
      Question_5b: {
        index: 2,
        question: "What is your mood behind the wheel?",
        next: "Question_6b",
        answers: [
          {
            id: "red",
            label: "Rapid Red: Bold and Energetic",
            img: "/img/quiz/5.2/5.2A.jpg",
          },
          {
            id: "yellow",
            label: "Grabber Yellow: Cheerful and Optimistic",
            img: "/img/quiz/5.2/5.2B.jpg",
          },
          {
            id: "black",
            label: "Shadow Black: Sophisticated and Powerful",
            img: "/img/quiz/5.2/5.2C.jpg",
          },
          {
            id: "blue",
            label: "Grabber Blue: Calm and Serene",
            img: "/img/quiz/5.2/5.2D.jpg",
          },
        ],
      },
      Question_6a: {
        index: 3,
        question: "Which car style fits you best?",
        next: "page_roadtrip",
        answers: [
          {
            id: "classic",
            label: "Classic: Timeless and Iconic",
            img: "/img/quiz/6.1/6.1A.jpg",
            infoTitle: "A style that matches your character:",
            infoImg: "/img/quiz/6.1/6.1follow.jpg",
            infoText:
              "The <b>F‑150 Lightning®</b> comes in the following styles: <ul class='mt-2'><li><strong class='font-bold'>Pro:</strong> Work hard, play hard</li><li><strong class='font-bold'>XLT:</strong> Perform at a higher level.</li><li><strong class='font-bold'>Flash™:</strong> High value, high tech.</li><li><strong class='font-bold'>Lariat®:</strong> Be a tech titan.</li><li><strong class='font-bold'>Platinum:</strong> Have it all.</li></ul>",
          },
          {
            id: "rally",
            label: "Rugged: Adventurous and Durable",
            img: "/img/quiz/6.1/6.1B.jpg",
            infoTitle: "A style that matches your character:",
            infoImg: "/img/quiz/6.1/6.1follow.jpg",
            infoText:
              "The <b>F‑150 Lightning®</b> comes in the following styles: <ul class='mt-2'><li><strong class='font-bold'>Pro:</strong> Work hard, play hard</li><li><strong class='font-bold'>XLT:</strong> Perform at a higher level.</li><li><strong class='font-bold'>Flash™:</strong> High value, high tech.</li><li><strong class='font-bold'>Lariat®:</strong> Be a tech titan.</li><li><strong class='font-bold'>Platinum:</strong> Have it all.</li></ul>",
          },
        ],
      },
      Question_6b: {
        index: 3,
        question: "Which car style fits you best?",
        next: "page_roadtrip",
        answers: [
          {
            id: "classic",
            label: "Classic: Timeless and Iconic",
            img: "/img/quiz/6.2/6.2A.jpg",
            infoTitle: "A style that matches your character:",
            infoImg: "/img/quiz/6.2/6.2follow.jpg",
            infoText:
              "The <b>Mustang Mach‑E</b>® comes in the following styles: <ul class='mt-2'><li><strong class='font-bold'>Select:</strong> Enter into electric.</li><li><strong class='font-bold'>Premium:</strong> Make it your own.</li><li><strong class='font-bold'>GT:</strong> Exhilarating Performance.</li><li><strong class='font-bold'>Mustang Mach‑E® Rally:</strong> The fun starts where the pavement ends.</li></ul>",
          },
          {
            id: "rally",
            label: "Rally: Adventurous and Stylish",
            img: "/img/quiz/6.2/6.2B.jpg",
            infoTitle: "A style that matches your character:",
            infoImg: "/img/quiz/6.2/6.2follow.jpg",
            infoText:
              "The <b>Mustang Mach‑E</b>® comes in the following styles: <ul class='mt-2'><li><strong class='font-bold'>Select:</strong> Enter into electric.</li><li><strong class='font-bold'>Premium:</strong> Make it your own.</li><li><strong class='font-bold'>GT:</strong> Exhilarating Performance.</li><li><strong class='font-bold'>Mustang Mach‑E® Rally:</strong> The fun starts where the pavement ends.</li></ul>",
          },
        ],
      },
    },
  },
  roadtrip: {
    title: "Enjoy your EV<br />{startBlue}roadtrip{endBlue}<br />experience",
    questions: {
      Question_7: {
        index: 0,
        question: "Do you long to drive hands-free?",
        next: "Question_8",
        answers: [
          {
            id: "yes",
            label: "Yes!",
            img: "/img/quiz/7/7A.jpg",
            infoTitle: "",
            infoImg: "/img/quiz/7/7follow.jpg",
            infoText:
              "The <b>BlueCruise Hands-Free Driving feature</b>* in all Ford EVs lets you experience the freedom of safe, hands-free driving. Engage BlueCruise on supported highways and enjoy automated driving while keeping your hands relaxed on the wheel, making for easy, enjoyable and less stressful trips.<br /><small>*Available Feature. Equipped Ford vehicles come with either a complimentary BlueCruise trial period or an included BlueCruise connected service plan duration, after which purchase is required.</small>",
          },
          {
            id: "no",
            label: "No!",
            img: "/img/quiz/7/7B.jpg",
            infoTitle: "",
            infoImg: "/img/quiz/7/7follow.jpg",
            infoText:
              "The <b>BlueCruise Hands-Free Driving feature</b>* in all Ford EVs lets you experience the freedom of safe, hands-free driving. Engage BlueCruise on supported highways and enjoy automated driving while keeping your hands relaxed on the wheel, making for easy, enjoyable and less stressful trips.<br /><small>*Available Feature. Equipped Ford vehicles come with either a complimentary BlueCruise trial period or an included BlueCruise connected service plan duration, after which purchase is required.</small>",
          },
        ],
      },
      Question_8: {
        index: 1,
        question: "Do You Sing in the Shower?",
        next: "Question_9",
        answers: [
          {
            id: "yes",
            label: "Yes! The acoustics are unreal.",
            img: "/img/quiz/8/8A.jpg",
            infoTitle: "",
            infoImg: "/img/quiz/8/8follow.jpg",
            infoText:
              "<b>Ford SYNC® Technologies</b> offer hands-free in-vehicle entertainment and intelligent information systems. Stream your music, podcasts and audiobooks or use Alexa for voice commands, you can turn every drive into a personalized concert or podcast session tailored to your preferences.",
          },
          {
            id: "no",
            label: "No, I prefer to zone out in the shower.",
            img: "/img/quiz/8/8B.jpg",
            infoTitle: "",
            infoImg: "/img/quiz/8/8follow.jpg",
            infoText:
              "<b>Ford SYNC® Technologies</b> offer hands-free in-vehicle entertainment and intelligent information systems. Stream your music, podcasts and audiobooks or use Alexa for voice commands, you can turn every drive into a personalized concert or podcast session tailored to your preferences.",
          },
        ],
      },
      Question_9: {
        index: 2,
        question: "Before heading out for a road trip, how carefully do you inspect your car?",
        next: "Question_10",
        answers: [
          {
            id: "mechanic",
            label: "Thorough inspection worthy of a mechanic's workshop.",
            img: "/img/quiz/9/9A.jpg",
            infoTitle: "Thorough inspection worthy of a mechanic's workshop.",
            infoImg: "/img/quiz/9/9follow.jpg",
            infoText:
              "No matter your preference, Ford EVs' advanced safety features provide peace of mind on every journey. From comprehensive driver-assist technologies to robust structural integrity and materials, Ford prioritizes your comfort and safety on the road. All without compromising looks, personality and an unmatched driving experience.",
          },
          {
            id: "tires",
            label: "Kick the tires. We got this!",
            img: "/img/quiz/9/9B.jpg",
            infoTitle: "Kick the tires. We got this!",
            infoImg: "/img/quiz/9/9follow.jpg",
            infoText:
              "No matter your preference, Ford EVs' advanced safety features provide peace of mind on every journey. From comprehensive driver-assist technologies to robust structural integrity and materials, Ford prioritizes your comfort and safety on the road. All without compromising looks, personality and an unmatched driving experience.",
          },
        ],
      },
      Question_10: {
        index: 3,
        question: "What's your jam for outdoor adventures?",
        answers: [
          {
            id: "camping",
            label: "Camping under the stars",
            img: "/img/quiz/10/10A.jpg",
            infoImg: "/img/quiz/10/10follow.jpg",
            infoTitle: "Power where you need it",
            infoText:
              "Whether you're pitching tents or mountain biking, Ford EVs have you covered with its <b>Pro Power Onboard</b> feature. From powering camping cooking equipment to charging electric mountain bikes, enjoy the great outdoors without worrying about battery life.",
          },
          {
            id: "trails",
            label: "Tearing up the trails on a mountain bike",
            img: "/img/quiz/10/10B.jpg",
            infoTitle: "Power where you need it",
            infoImg: "/img/quiz/10/10follow.jpg",
            infoText:
              "Whether you're pitching tents or mountain biking, Ford EVs have you covered with its <b>Pro Power Onboard</b> feature. From powering camping cooking equipment to charging electric mountain bikes, enjoy the great outdoors without worrying about battery life.",
          },
        ],
      },
    },
  },
  legals: {
    title: "Terms and conditions",
    content:
      "<p>Welcome to [Your Application Name]! Our application is designed to provide entertainment, and we're excited to have you on board. By using our application, you agree to these terms and conditions. If you disagree with any part of the terms, please do not use our application.</p><h3>Accounts</h3><p>To access some features of the application, you may need to create an account. Ensure that the information you provide is complete and current. You are responsible for safeguarding your account password and for all activities under your account.</p><h3>Use of Service</h3><p>[Your Application Name] is intended for entertainment purposes only. The information and content provided through the application are not meant to be accurate, reliable, or otherwise used for anything other than entertainment.</p><ul><li>Lorem</li><li>Ipsum</li><li>Dolores</li></ul><p>You agree not to use the application for any purpose that is illegal or prohibited by these terms.</p><h3>Content</h3><p>You understand that all information, such as text, graphics, or other material, is for entertainment and may not be accurate or reliable.</p><h3>Termination</h3><p>We may terminate or suspend access to our application immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the terms.</p><h3>Changes</h3><p>We reserve the right to modify or replace these terms at any time. By continuing to access or use our application after those revisions become effective, you agree to be bound by the revised terms.</p><h3>Contact Us</h3><p>If you have any questions about these terms, please contact us at [Your Contact Information]. By accessing or using [Your Application Name], you agree to be bound by these terms.</p>",
  },
  profile: {
    title: "Your profile",
    explorer: {
      title: "Tough {startXL}Adventurer{endXL}",
      text: "You embrace the thrill of adventure. Whether conquering off-road trails or embarking on epic road trips, you seek out adrenaline-fueled experiences. With a bold spirit and a love for the great outdoors, you're always ready for your next rugged adventure.",
      bannerImg: "/img/quiz/profile/tough_adventurer.jpg",
      bannerAlt: "Tough Adventurer",
      categoryA: "Action-packed Adventure",
      categoryAImg: "/img/profile_action.jpg",
      categoryB: "Rugged and Capable",
      categoryBImg: "/img/profile_f150.jpg",
      categoryBImgLabel: "F‑150<br />Lightning",
    },
    glam: {
      title: "Unbridled {startXL}Glam Camper{endXL}",
      text: "Luxury meets adventure under the open sky. You appreciate the finer things in life, indulging in gourmet delights amidst stunning natural landscapes. With a taste for sophistication and a passion for outdoor exploration, you redefine camping in style.",
      bannerImg: "/img/quiz/profile/unbridled_glam_camper.jpg",
      bannerAlt: "Unbridled Glam Camper",
      categoryA: "Action-packed Adventure",
      categoryAImg: "/img/profile_action.jpg",
      categoryB: "Unbridled Rebel",
      categoryBImg: "/img/profile_machE.jpg",
      categoryBImgLabel: "Mustang<br />Mach‑E",
    },
    epicurean: {
      title: "Bold {startXL}Gourmet{endXL}",
      text: "Combining ruggedness with culinary flair, you savor the thrill of adventure and the taste of gourmet delights. From conquering rugged terrain to indulging in epicurean experiences, you embody the perfect blend of adventure and refinement.",
      bannerImg: "/img/quiz/profile/bold_gourmet.jpg",
      bannerAlt: "Bold Gourmet",
      categoryA: "Gourmet Epicurean Delight",
      categoryAImg: "/img/profile_gourmet.jpg",
      categoryB: "Rugged and Capable",
      categoryBImg: "/img/profile_f150.jpg",
      categoryBImgLabel: "F‑150<br />Lightning",
    },
    stylish: {
      title: "Trailblazing {startXL}Foodie{endXL}",
      text: "With a flair for style and a passion for culinary adventures, you embrace life's pleasures with sophistication. From exploring vibrant city streets to savoring gourmet delights, you appreciate the finer things in life and always travel in style.",
      bannerImg: "/img/quiz/profile/trailblazing_foodie.jpg",
      bannerAlt: "Trailblazing Foodie",
      categoryA: "Gourmet Epicurean Delight",
      categoryAImg: "/img/profile_gourmet.jpg",
      categoryB: "Unbridled Rebel",
      categoryBImg: "/img/profile_machE.jpg",
      categoryBImgLabel: "Mustang<br />Mach‑E",
    },
    zen: {
      title: "Rugged {startXL}Zen Master{endXL}",
      text: "You strive for serenity in every journey. Grounded and calm, you seek tranquility amidst nature's beauty, embracing both the robust spirit and the peacefulness of the great outdoors. With a focus on mindfulness and inner peace, you approach every adventure with a sense of harmony.",
      bannerImg: "/img/quiz/profile/rugged_zen_master.jpg",
      bannerAlt: "Rugged Zen Master",
      categoryA: "Relaxing Retreat",
      categoryAImg: "/img/profile_relax.jpg",
      categoryB: "Rugged and Capable",
      categoryBImg: "/img/profile_f150.jpg",
      categoryBImgLabel: "F‑150<br />Lightning",
    },
    relaxation: {
      title: "Unconventional {startXL}Relaxation Expert{endXL}",
      text: "You indulge in the art of relaxation. Whether unwinding on serene beaches or enjoying leisurely road trips through scenic landscapes, you prioritize peace and tranquility while indulging your rebellious spirit for adventure. With a laid-back attitude and a love for unconventional leisure, you redefine the art of relaxation.",
      bannerImg: "/img/quiz/profile/unconventional_relaxation_expert.jpg",
      bannerAlt: "Unconventional Relaxation Expert",
      categoryA: "Relaxing Retreat",
      categoryAImg: "/img/profile_relax.jpg",
      categoryB: "Unbridled Rebel",
      categoryBImg: "/img/profile_machE.jpg",
      categoryBImgLabel: "Mustang<br />Mach‑E",
    },
    book: "Book a test drive",
  },
  survey: {
    title: "Fill in your post-drive survey and receive a surprise thank you gift",
    submit: "Submit",
    wait: "Please wait...",
    error: "There was an error. Please try again.",
    back: "Back to survey",
    bookExtendedDrive: "Book an extended test drive",
    bookExtendedDriveURL: "https://www.ford.ca/schedule-a-test-drive/",
    yes: "Yes",
    no: "No",
    reset: "Reset",
    eventNotFound: "No event found for this province",
    resetTimer: "Reset ({seconds})",
    success: "Thank you!",
  },
  schedule: {
    title: "Book your test drive",
    next: "Next",
    skip: "Skip",
    modal: {
      heading: "Have you booked your test drive?",
      content: "You will not be able to book once you move forward.",
      yes: "Yes",
      back: "Go back",
    },
  },
  book: {
    heading: "Book a test drive{b} and Experience Ford{b} Electrified Vehicles",
    select: "Select your festival below",
    back: "Back",
  },
  success: {
    title: "Thank you for{b} booking your{b} test drive!",
    home: "Home",
  },
  waiver: {
    title: "Waiver",
    text_1: `In consideration of riding in one or more automobiles at the <b>EV RoadTrip of Lifetime</b> event (the “Event”) and otherwise participating in the Event, I,{name}, the undersigned, on behalf of myself and anyone who obtains any rights from or through me, acknowledge, represent and agree as follows :`,
    tutor_title: "*Complete the following if the person above is a minor in their province or territory of residence:",
    tutor_description: "I, the undersigned, warrant and represent that I am the parent or legal guardian of {name}, whose signature appears above. I have read the above release agreement and am fully aware of its contents, and I hereby give my permission and consent to all the foregoing.",
    admin_warning: "The Administrators will not use any personal information provided in this waiver other than for the purposes described above and will not transfer or make personal information available to any other person, organization, or third party, except as required by law.",
    submit: "I have read and approve this waiver.",
    success: "Thank you!",
    error: "Something wrong happened... Please try again later...",
    reset: "Reset ({seconds})",
    optIn: "Check here for communications, including emails, from Ford Motor Company of Canada, Limited (Ford of Canada) and the Lincoln Motor Company (Lincoln)* about our products, services, events, news, updates, offers, promotions, customized ads and more. You may withdraw consent at any time. Ford of Canada’s Privacy Policy, including your right to access or correct your personal information, opt-out (deletion from marketing lists), our service provider practices and storage of data in the U.S is available from the Event Staff on-site. If you have any questions you may also contact a Customer Service Representative at 1-800-565-FORD (3673) or write us at The Canadian Road P.O. Box 2000, Oakville, Ontario, Canada L6K 0C8.",
    termsText: "<p>*Ford Motor Company of Canada, Limited carries on business as The Lincoln Motor Company.</p><br/><p>THE EVENT STAFF RESERVES THE RIGHT TO REFUSE ANY PARTICIPANTS DEMONSTRATING UNRULY OR INAPPROPRIATE BEHAVIOUR THAT MAY RESULT IN INJURY TO THEMSELVES OR OTHERS OR DAMAGE TO PROPERT Y. THE POSSESSION OR USE OF ANY ALCOHOLIC BEVERAGES OR ILLEGAL SUBSTANCES WILL RESULT IN THE AUTOMATIC DISQUALIFICATION OF THE PARTICIPANT FROM THE EVENT. SUCH DETERMINATIONS OF REFUSAL/DISQUALIFICATION SHALL BE AT THE SOLE DISCRETION OF THE FORD DRIVE AND EVENT STAFF, WHOSE DECISIONS SHALL BE FINAL IN ALL RESPECTS.</p><br/><p>The terms and provisions of this instrument shall be binding upon the heirs, executors or administrators of the undersigned and anyone who obtains any rights from or through the undersigned. I acknowledge that I have read and fully understand all of the foregoing, I am responsible for my actions, and understand that I have given up substantial rights by signing this instrument and have signed it freely and without any inducement or assurance of any nature and intend it be a COMPLETE AND UNCONDITIONAL RELEASE OF ALL LIABILITY to the greatest extent allowed by law. I acknowledge that I have been offered the opportunity not to participate in the Activities if I do not wish to execute this instrument.</p>",
    text: `
<ul>
  <li>
    <b>Assumption of all risks</b><br/>
    I fully understand that the use, riding in, operation, *sitting as a passenger*, inspection or testing of said automobile or automobiles or other participation in the Event (collectively, the “Activities”) can be HAZARDOUS, and involve known and unanticipated risks that could result in BODILY INJURY, ILLNESS, DEATH, DAMAGE OR LOSS to myself, my property, and to other third parties and their property, which may be caused by my own actions, or inactions, those of others participating in the Activities, the vehicle(s), roads, surfaces, facilities, weather, other vehicles, persons or obstacles, and other conditions in which the Activities take place, or the negligence or misconduct of any of the “Releasees” named below (including, without limitation, the negligence or misconduct of the driver or passengers of the vehicle on which I am a passenger); and I fully accept and ASSUME ALL SUCH RISKS and all responsibility for losses, costs, injuries, death and/or damages I, or any third parties incur as a result of my participation in the Activities, even if such injuries, death or property damage occurs in a manner that is not foreseeable at the time I sign this release. I represent that I understand the nature of the Activities, including, without limitation, the inherent dangers of driving or being a passenger in a vehicle on public roads.
  </li>

  <li>
    <b>Releases and Discharge</b><br/>
    I hereby forever and irrevocably RELEASE, DISCHARGE, INDEMNIFY, DEFEND AND HOLD HARMLESS Ford Motor Company of Canada, Limited (“Ford”), Cinco, and all of their respective parent, affiliates, subsidiaries, officers, directors, managers, representatives, agents, employees and successors and assigns (collectively, the “Releasees”), from and against any and all actions, causes of action, claims, damages, losses, demands, or liabilities of whatever kind and nature, including but not limited to bodily injury, death or property damage (collectively, “Losses”) arising from, or in any way connected to, the undersigned's participation in the Activities, including, but not limited to Losses caused or alleged to be caused by, in whole or in part, by the NEGLIGENCE OF ANY RELEASEE or otherwise. Losses also include all costs, losses or expenses in connection therewith arising from, or by reason of, any property damage arising out of any accident or occurrence during, or in connection with, said Activities, whether negligent or otherwise. I understand that I may hereafter discover claims, facts, matters and/or Losses in addition to or different from those that I now know or believe to be true with respect to the Activities and the matters discussed herein. Nevertheless, I intend to fully and completely waive and release the Releasees from all such unknown claims, matters and Losses. I AGREE NOT TO SUE any Releasee or cause any Releasee to be sued regarding any matter released herein.
  </li>

  <li>
    <b>Certification</b><br/>
    I certify that:<br/>
    <ol>
      <li>
        I am 18 years of age or older, I have a valid driver's license and am authorized to drive in the location in which the Event is being conducted;
      </li>
      <li>
        I have valid automobile insurance throughout the testing period that covers my operation of the automobile(s) during the Event;
      </li>
      <li>
        I am not aware of any medical condition or other circumstance that may impair my ability to participate in the Activities or that would make my participation in the Activities an undue risk to myself, other participants in the Activities, Releasees and/or any other person; and
      </li>
      <li>
        Prior to participating in the Event, I have not consumed, and during the Event I will not consume, any alcoholic beverages or illegal substances.
      </li>
    </ol>
  </li>

  <li>
    <b>Compliance with Laws</b></br>
    I agree to obey all applicable motor vehicle laws, codes and regulations and to exercise the same caution and prudence that I would employ in using any personally owned vehicle (but no less than a reasonable care) and further agrees not to permit anyone to drive the automobile(s).
  </li>

  <li>
    <b>Use of Safety Belts and Restraints</b><br/>
    I agree to ensure the proper use of safety belts and child safety restraints for all occupants of the automobile(s). I agree to follow all directions and/or instructions given by the Releasees.
  </li>

  <li>
    <b>Medical Treatment Authorization</b><br/>
    I hereby authorize medical treatment deemed necessary if I incur any injury or illness while participating in the Activities. I either have appropriate insurance or, in its absence, agree to pay all costs of rescue and/or medical services as may be incurred on my behalf. Notwithstanding the foregoing, this instrument does not impose any obligation (beyond any applicable legal requirements) on any of the Releasees to act in the event of a medical emergency involving myself and I hereby WAIVE AND RELEASE the Releasees from any and all Losses of any kind whatsoever, whether caused by the Releasees' negligence or for any other reason, arising out of or in connection with any illness, bodily injuries or death to myself in any way related to Releasees' actions or failure to act in furtherance of this grant of permission to Releasees to obtain medical treatment on my behalf.
  </li>

  <li>
    <b>Media Use Release</b><br/>
    I hereby:<br/>
    <ol>
      <li>
        Grant permission to the Releasees the right to my name, voice, physical likeness (in any format, including photograph and video, whether taken/recorded by the Releasees or taken/recorded by me and provided to the Releasees) and personal opinions/statements expressed regarding Ford and its vehicles, features, and services (the “Material”);
      </li>
      <li>
        Acknowledge and agree that I will not acquire or otherwise retain any copyright or other intellectual property or commercial rights, including moral rights to, or in, my Material;
      </li>
      <li>
        Acknowledge and agree that the Material, and translations thereof, may be used by the Releasees as often and for as long as the Releasees consider appropriate in their sole and absolute discretion;
      </li>
      <li>
        Acknowledge and agree that I will not receive any fee, royalty, or other compensation for the use of the Material, and translations thereof, by the Releasees;
      </li>
      <li>
        Acknowledge and agree that the Releasees can use the Material (and translations thereof), in whole or in part, without notice and further consent, in any media, including and without limitation, in broadcast (e.g. television, radio and web), and in any form, such as the use of my image (photograph or video), voice, and/or written representations;
      </li>
      <li>
        Waive any right inspect and/or approve the finished product or the advertising copy that may be used in connection therewith;
      </li>
      <li>
        Certify that the content of the Material is original to me, true, based on my personal knowledge and experience, does not refer to or depict any persons or entities (other than myself and Releasees) without their express permission, does not infringe upon the copyrights, trademarks, rights of privacy, rights of publicity or other intellectual property or other rights of any person or entity (other than me), was created in a legal and safe manner, and is owned by me;
      </li>
      <li>
        Acknowledge and agree that I am aware that only part of the Material may be used by the Releasees, and to that end, I confirm that each statement and representation in the Material is true, even if reproduced on its own and without reference to the entirety of the Material;
      </li>
      <li>
        Understand and agree that I will not be given an opportunity to select whether the Material, and translations thereof, will be used in whole or in part, and where only used in part, I acknowledge and agree that I will not be given an opportunity to decide which part will be used;
      </li>
      <li>
        Release, forever discharge and agree to save harmless, the Releasees from any action, cause of action, or claims arising to date or in the future in which I may have by reason of any cause, matter or thing whatever existing or arising in connection with the use of the material.
      </li>
    </ol>
  </li>

  <li>
    <b>General</b><br/>
    I hereby forever and irrevocably waive any and all rights, if any, to rescind, limit or disaffirm this instrument. If any provision hereof is unenforceable, it shall be modified to the extent reasonably necessary to make the provision legal, valid and binding, and the remainder of this instrument shall remain enforceable to the full extent allowed by law. No statements regarding the effect of this instrument, not contained in this instrument, shall modify the terms hereof. I make these representations with the full understanding that the Releasees are relying on these representations. In any proceeding or other attempt to enforce, construe or to determine the validity of this instrument, the non-prevailing party will pay the reasonable expenses of the prevailing party, including, without limitation, reasonable legal fees and costs. This Release shall be governed by and construed in accordance with the laws of Ontario, Canada, without regard to conflict of laws principles. Sole and exclusive jurisdiction and venue for any dispute concerning this Release shall be in Ontario, Canada, and I hereby submit to sole and exclusive personal jurisdiction of such courts for any such dispute and irrevocably waive any and all rights to object to such jurisdiction.
  </li>
</ul>
`,
  },
  provinces: {
    britishColumbia: "British Columbia",
    ontario: "Ontario",
    quebec: "Quebec"
  }
};
